import React from 'react'
import dexscreener from "../assets/dexscreener.svg"
import fumblenow from "../assets/fumblenow.svg"
import oopsyroll from "../assets/oopsyroll.svg"
import oopsyheroimg from "../assets/oopsyheroimg.svg" 
import oopsylogo from "../assets/oopsylogo.svg" 
import oopsyherotext from "../assets/oopsyherotext.svg" 



function Hero() {
  return (
    <div className='HERO hidden lg:flex flex-col lg:pt-24 overflow-hidden'>
        <div className='flex flex-col items-center'>
            <img src={oopsylogo} alt="" className=' w-[1074px] mt-[-53px]'/> 
        </div>

        <div className='relative mb-[-85px]'>
          <img src={oopsyheroimg} alt="" className='absolute bottom-[70px] left-[525px] w-[300px] ' />
        </div>  
        
        <div className='relative'>
          <img src={oopsyherotext} alt="" className='absolute bottom-[70px] left-[490px] top-[60px] w-96' />
        </div> 

        <div className='flex flex-row justify-between w-full px-8'>
                
                <a href="https://raydium.io/swap/?outputCurrency=38JU3Ni9C3tQnWKqAR8UjGpZ3gwtPCeN3Jmia1F9feh6" target="_blank" rel="noopener noreferrer">
                <img src={fumblenow} alt="" />
                </a>
                
                <a href="https://dexscreener.com/solana/38JU3Ni9C3tQnWKqAR8UjGpZ3gwtPCeN3Jmia1F9feh6" target="_blank" rel="noopener noreferrer">
                <img src={dexscreener} alt="" />
                </a>
                
                
        </div>

        <div>
          <img src={oopsyroll} alt="" />
        </div>

    </div>
  )
}

export default Hero