import React from 'react'
import Social from "../assets/socials.svg"
import oopsytwitter from "../assets/oopsytwitter.svg"
import oopsytelegram from "../assets/oopsytelegram.svg"
import oopsysocialsimg from "../assets/oopsysocialsimg.svg"
import jointhefumble from "../assets/jointhefumble.svg"



function Socials() {
  return (
    <div className=' flex flex-col items-center justify-center p-4 lg:p-10 relative bg-[#1D1D1D] w-full'>   
      <div className='flex flex-col items-center'>
        <img src={Social} alt="" className='pb-10' />
        <img src={jointhefumble} alt="" className='pb-10' />
      </div>
    
      <div className=' flex flex-row justify-center pt-10 lg:pt-0'>
        
        <a href="https://t.me/oopsiedaisysol" target="_blank" rel="noopener noreferrer">
        <img src={oopsytwitter} alt="" className='px-5'/>
        </a>
        
        <a href="https://twitter.com/Oopsie_Daisy" target="_blank" rel="noopener noreferrer">
        <img src={oopsytelegram} alt="" className='px-5'/>
        </a>
        
      </div>

      <div className='flex justify-end'>
        <img src={oopsysocialsimg} alt="" className=' w-[400px]'/>
      </div>
    </div>
  )
}

export default Socials