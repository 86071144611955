import React from 'react'
import tokenomicss from "../assets/tokenomics.svg"
import tokenaddressorgin from "../assets/tokenaddressorgin.svg"
import tokensupply from "../assets/tokensupply.svg"
import oopsyheroimg from "../assets/oopsyheroimg.svg"



function Tokenomicsmobile() {
  return (
    <div className='relative lg:hidden flex flex-col px-4 items-center mt-5' >
            <div className='flex flex-col items-center p-4 lg:p-10 relative'>
                <img src={tokenomicss} alt="" />
                <p className='text-center lg:text-2xl w-auto lg:w-[877px] text-white mt-4'>If you're going to be irresponsible with your money, maybe grab some Oopsie Daisy, the meme coin to commemorate all the little accidents and scams that degens get hit with on a daily basis.</p>
            </div>

        
            <img src={oopsyheroimg} alt="" className='w-48 top-0' />
         
         <div className='flex flex-col  justify-center -2 -black mb-[s221px] '>
        
                <img src={tokenaddressorgin} alt="" className='-m ' />
           
                <img src={tokensupply} alt="" className='m r-2'  />
           
        </div>  
    </div>     
  )
}

export default Tokenomicsmobile