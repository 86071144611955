import React from 'react'
import Hero from "./components/Hero"
import Heromobile from "./components/Heromobile"
import Pardonme from "./components/Pardonme"
import Pardonmemobile from "./components/Pardonmemobile"

import Tokenomics from "./components/Tokenomics"
import Tokenomicsmobile from "./components/Tokenomicsmobile"
import Socials from "./components/Socials"
import Header from "./components/Header"


function App() {
  return (
    <div className='relative flex flex-col items-center burger'>
      <Header/>

      <Hero/>
      <Heromobile/>

      <Pardonme/>
      <Pardonmemobile/>

     <Tokenomics/>
     <Tokenomicsmobile/>

       <Socials/>

    </div>
  )
}

export default App