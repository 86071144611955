import React from 'react'
import tokenomicss from "../assets/tokenomics.svg"
import tokenaddressorgin from "../assets/tokenaddressorgin.svg"
import tokensupply from "../assets/tokensupply.svg"
import oopsyheroimg from "../assets/tokenomicsimg.svg"



function Tokenomics() {
  return (
    <div className='relative hidden lg:flex flex-col'>
        <div className='flex flex-col items-center p-4 lg:p-10 relative'>
            <img src={tokenomicss} alt="" />
            <p className='text-center lg:text-3xl w-auto lg:w-[877px] text-white mt-10 px-10'>
              If you're going to be irresponsible with your money, maybe grab some Oopsie Daisy, the meme coin to commemorate all the little accidents and scams that degens get hit with on a daily basis.
              </p>
        </div>

       
            <img src={oopsyheroimg} alt="" className='absolute top-[160px] z-0 left-[-97px]' />
        
        <div className='flex flex-row  justify-center z-10 -black mb-[221px] '>
        
                <img src={tokenaddressorgin} alt="" className='-mb-96 mw-[520px] mr-[-71px]' />
           
                <img src={tokensupply} alt="" className='mw-[520px] px-10 -2'  />
           
        </div>
    </div>     
  )
}

export default Tokenomics