import React from 'react'
import herobutton from "../assets/herobutton.svg"

function Header() {
  return (
   <>
    {/* <div className='flex flex-col items-centerg mt-4 fixedz z-50 top-1'>
        <img src={herobutton} alt="" className=''/>

    </div> */}
    
    <div className="OUTER w-full lg:w-[710px] h-8 lg:h-[58px] rounded-full border-4 lg:border-8 border-black flex align-middle overflow-clip
     mt-4 fixed z-50 top-1
    ">

      <div className="INNNER w-full flex flex-row justify-evenly">

      <div className="STUFF HOME flex items-center bg-[#D9C100]">
        <a href="#" target="_blank" rel="noopener noreferrer" className='text-xs lg:text-3xl  px-[18px] lg:px-[44px] 
        hover:text-white transition-all duration-150 '>
          HOME
        </a>
      </div>

      <div className="STUFF flex items-center ABOUT bg-[#000000]">
        <a href="#pardonme" className='text-white text-xs lg:text-3xl px-[20px] lg:px-[35px]
        hover:text-[#D9C100]  transition-all duration-150'>
          ABOUT
        </a>
      </div>

      <div className="STUFF flex items-center BUY bg-[#ffffff]">
        <a href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=38JU3Ni9C3tQnWKqAR8UjGpZ3gwtPCeN3Jmia1F9feh6&fixed=in" target="_blank" rel="noopener noreferrer" className='text-xs lg:text-3xl  px-[19px] lg:px-[35px]
        hover:text-[#4FF776] transition-all duration-150 '>
          BUY
        </a>
      </div>

      <div className="STUFF flex items-center TELEGRAM bg-[#4FF776]">
        <a href="https://t.me/oopsiedaisysol" target="_blank" rel="noopener noreferrer" className='text-xs lg:text-3xl  px-[15px] lg:px-[25px]
        hover:text-white transition-all duration-150 '>
          TELEGRAM
        </a>
      </div>
     
       </div>

    </div>
    </>
  )
}

export default Header