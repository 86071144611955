import React from 'react'
import pardonmee from "../assets/pardonme.svg"
import oopsypardonmeimg from "../assets/oopsypardonmeimg.svg"
import pardonmebuy from "../assets/pardonmebuy.svg"


function Pardonmemobile() {
  return (
    <div className=' PARDONME lg:hidden flex flex-col  lg:flex-row lg:px-32 px-4 relative bg-[#1D1D1D] '>
        
    <div className='lg:w-6/12 flex flex-col items-center justify-center'>
        <img src={oopsypardonmeimg} alt="" className='w-52 mt-4' /> 
   
    </div>
    
    <div className='lg:w-6/12 pt-10'>
        <img src={pardonmee} alt="" className=''/>
        <p className='text-center lg:text-2xl w-auto lg:w-[520px] text-white mt-5'>Did you trust another anonymous Twitter user with your hard earned money again? Maybe you thought you'd make an easy 100x and escape your wage cage. 
            <br/> <br/>
            But that dev had other plans, didn't he? Oopsie Daisy! Your money went poof. 
            <br/> 
            Better luck next time. </p>

        <img src={pardonmebuy} alt="" className=' mdt-[-100px] dml-80 mb-10'/>
    </div>
</div>
  )
}

export default Pardonmemobile