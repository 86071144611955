import React from 'react'
import pardonmee from "../assets/pardonme.svg"
import oopsypardonmeimg from "../assets/oopsypardonmeimg.svg"
import pardonmebuy from "../assets/pardonmebuy.svg"






function Pardonme() {
  return (
    <div className='PARDONME hidden lg:flex flex-col lg:flex-row lg:w-full px-4 relative bg-[#1D1D1D] ' id='pardonme'>
        
        <div className='lg:w-6/12 flex flex-col items-center'>
            <img src={oopsypardonmeimg} alt="" className='pr-[191px]' />
            <img src={pardonmebuy} alt="" className=' mt-[-100px] ml-48 z-10' />
         
        </div>
        
        <div className='lg:w-6/12 pt-32'>
            <img src={pardonmee} alt="" className=''/>
            <p className='text-center lg:text-2xl mt-14 rotate-6 w-auto lg:w-[520px] text-white'>Did you trust another anonymous Twitter user with your hard earned money again? Maybe you thought you'd make an easy 100x and escape your wage cage. 
            <br/> <br/>
            But that dev had other plans, didn't he? Oopsie Daisy! Your money went poof. 
            <br/> 
            Better luck next time. 
</p>
        </div>
    </div>
  )
}

export default Pardonme