import React from 'react'
import dexscreener from "../assets/dexscreener.svg"
import fumblenow from "../assets/fumblenow.svg"
import oopsyroll from "../assets/oopsyroll.svg"
import oopsyheroimg from "../assets/oopsyheroimg.svg" 
import oopsylogo from "../assets/oopsylogo.svg" 
import oopsyherotext from "../assets/oopsyherotext.svg" 



function Heromobile() {
  return (
    <div className='HEROMOBILE lg:hidden flex flex-col w-full pt-20 overflow-clip'>
    <div className='flex flex-col items-center'>
        <img src={oopsylogo} alt="" className=' w-[1074px] '/> 
    </div>

   <div className='relative smb-[-85px] w-full flex items-center justify-center'>
      <img src={oopsyheroimg} alt="" className='absolute bottom-[4px] w-[138px] ' />
    </div>  
    
   <div className='relative flex items-center justify-center'>
      <img src={oopsyherotext} alt="" className='absoluted w-[171px] bottom-[70px] ldeft-[490px] top-[6px] ' />
    </div> 

      <div className='flex flex-col justify-between w-full px-8'>
            <img src={fumblenow} alt="" className='my-3'/>
            <img src={dexscreener} alt="" className='my-3'/>
    </div>

    <div className='w-full flex justify-center items-center'> 
      <img src={oopsyroll} alt="" className='w-[1007px] max-w-none' />
    </div>

</div>
  )
}

export default Heromobile